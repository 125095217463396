var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panels",
    {
      attrs: { value: _vm.value, multiple: "", accordion: "" },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event.target.value)
        }
      }
    },
    [
      _vm.dragAndDropMixin_items.some(function(x) {
        return x.group === "sections"
      })
        ? _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", { staticClass: "text-h6" }, [
                _vm._v("\n      Sections\n    ")
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    _vm._l(
                      _vm.dragAndDropMixin_items.filter(function(x) {
                        return x.group === "sections"
                      }),
                      function(draggable, dragIndex) {
                        return _c(
                          "v-col",
                          {
                            key: dragIndex,
                            staticClass: "pa-1",
                            attrs: { cols: "4" }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  block: "",
                                  icon: "",
                                  tile: "",
                                  elevation: "2",
                                  height: "100",
                                  draggable: ""
                                },
                                on: {
                                  mousedown: function($event) {
                                    return _vm.startDrag(draggable.id)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "pa-1" }, [
                                  _c(
                                    "span",
                                    { staticClass: "pa-1" },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(draggable.icon) +
                                            "\n                "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pa-1",
                                      staticStyle: { display: "block" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(draggable.title) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dragAndDropMixin_items.some(function(x) {
        return x.group === "basic"
      })
        ? _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", { staticClass: "text-h6" }, [
                _vm._v("\n      Basic elements\n    ")
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    _vm._l(
                      _vm.dragAndDropMixin_items.filter(function(x) {
                        return x.group === "basic"
                      }),
                      function(draggable, dragIndex) {
                        return _c(
                          "v-col",
                          {
                            key: dragIndex,
                            staticClass: "pa-1",
                            attrs: { cols: "4" }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  block: "",
                                  icon: "",
                                  tile: "",
                                  elevation: "2",
                                  height: "100",
                                  draggable: ""
                                },
                                on: {
                                  mousedown: function($event) {
                                    return _vm.startDrag(draggable.id)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "pa-1" }, [
                                  _c(
                                    "span",
                                    { staticClass: "pa-1" },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(draggable.icon) +
                                            "\n                "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pa-1",
                                      staticStyle: { display: "block" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(draggable.title) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dragAndDropMixin_items.some(function(x) {
        return x.group === "content"
      })
        ? _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", { staticClass: "text-h6" }, [
                _vm._v("\n      Content blocks\n    ")
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    _vm._l(
                      _vm.dragAndDropMixin_items.filter(function(x) {
                        return x.group === "content"
                      }),
                      function(draggable, dragIndex) {
                        return _c(
                          "v-col",
                          {
                            key: dragIndex,
                            staticClass: "pa-1",
                            attrs: { cols: "4" }
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: {
                                  top: "",
                                  disabled: _vm.disableDragTooltip,
                                  "open-delay": "1000"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  block: "",
                                                  icon: "",
                                                  tile: "",
                                                  elevation: "2",
                                                  height: "100",
                                                  draggable: ""
                                                },
                                                on: {
                                                  mousedown: function($event) {
                                                    return _vm.startDrag(
                                                      draggable.id
                                                    )
                                                  },
                                                  mouseleave: function($event) {
                                                    _vm.disableDragTooltip = false
                                                  }
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pa-1" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "pa-1" },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              draggable.icon
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "pa-1",
                                                      staticStyle: {
                                                        display: "block"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            draggable.title
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                      draggable.title2
                                                        ? _c("br")
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            draggable.title2
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("froala-view", {
                                  model: {
                                    value: draggable.content,
                                    callback: function($$v) {
                                      _vm.$set(draggable, "content", $$v)
                                    },
                                    expression: "draggable.content"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    ),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }