<template>
  <v-expansion-panels
    :value="value"
    multiple
    accordion
    @input="$emit('input', $event.target.value)"
  >
    <v-expansion-panel v-if="dragAndDropMixin_items.some(x => x.group === 'sections')">
      <v-expansion-panel-header class="text-h6">
        Sections
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row no-gutters>
          <v-col
            v-for="(draggable, dragIndex) in dragAndDropMixin_items.filter(x => x.group === 'sections')"
            :key="dragIndex"
            cols="4"
            class="pa-1"
          >
            <v-btn
              block
              icon
              tile
              elevation="2"
              height="100"
              draggable
              @mousedown="startDrag(draggable.id)"
            >
              <div class="pa-1">
                <span class="pa-1">
                  <v-icon>
                    {{ draggable.icon }}
                  </v-icon>
                </span>
                <span
                  style="display: block;"
                  class="pa-1"
                >
                  {{ draggable.title }}
                </span>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="dragAndDropMixin_items.some(x => x.group === 'basic')">
      <v-expansion-panel-header class="text-h6">
        Basic elements
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row no-gutters>
          <v-col
            v-for="(draggable, dragIndex) in dragAndDropMixin_items.filter(x => x.group === 'basic')"
            :key="dragIndex"
            cols="4"
            class="pa-1"
          >
            <v-btn
              block
              icon
              tile
              elevation="2"
              height="100"
              draggable
              @mousedown="startDrag(draggable.id)"
            >
              <div class="pa-1">
                <span class="pa-1">
                  <v-icon>
                    {{ draggable.icon }}
                  </v-icon>
                </span>
                <span
                  style="display: block;"
                  class="pa-1"
                >
                  {{ draggable.title }}
                </span>
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel v-if="dragAndDropMixin_items.some(x => x.group === 'content')">
      <v-expansion-panel-header class="text-h6">
        Content blocks
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row no-gutters>
          <v-col
            v-for="(draggable, dragIndex) in dragAndDropMixin_items.filter(x => x.group === 'content')"
            :key="dragIndex"
            cols="4"
            class="pa-1"
          >
            <v-tooltip
              top
              :disabled="disableDragTooltip"
              open-delay="1000"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  block
                  icon
                  tile
                  elevation="2"
                  height="100"
                  draggable
                  @mousedown="startDrag(draggable.id)"
                  @mouseleave="disableDragTooltip = false"
                  v-on="on"
                >
                  <div class="pa-1">
                    <span class="pa-1">
                      <v-icon>
                        {{ draggable.icon }}
                      </v-icon>
                    </span>
                    <span
                      style="display: block;"
                      class="pa-1"
                    >
                      {{ draggable.title }}
                      <br v-if="draggable.title2">
                      {{ draggable.title2 }}
                    </span>
                  </div>
                </v-btn>
              </template>
              <froala-view
                v-model="draggable.content"
              />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { dragAndDropMixin } from '@/shared/mixins/mailing'
export default {
  name: 'DragAndDrop',

  mixins: [dragAndDropMixin],

  props: ['value'],

  data () {
    return {
      disableDragTooltip: false
    }
  },

  methods: {
    startDrag (value) {
      this.disableDragTooltip = true
      localStorage.setItem('drag', value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
